<template>
  <div class="container-fluid footer-container position-relative">
    <div class="row footer-top">
      <div class="col-lg-5 col-sm-6 col-12 first-column">
        <p class="date mb-2 text-uppercase">26 - 28 May 2022</p>
        <h3>Waiting for you by the beach!</h3>
        <div class="social d-flex mt-4">
          <a
            v-if="social_links.facebook"
            :href="social_links.facebook"
            class="d-inline-block mr-4"
            target="_blank"
          >
            <i class="fab fa-facebook"></i>
          </a>
          <a
            v-if="social_links.instagram"
            :href="social_links.instagram"
            class="d-inline-block mr-4"
            target="_blank"
          >
            <i class="fab fa-instagram"></i>
          </a>
          <a
            v-if="social_links.soundcloud"
            :href="social_links.soundcloud"
            class="d-inline-block mr-4"
            target="_blank"
          >
            <i class="fab fa-soundcloud"></i>
          </a>
        </div>
      </div>
      <div class="col-lg-4 col-12">
        <div class="row">
          <div class="col second-column">
            <ul class="list-unstyled mb-0 mt-5">
              <li class="text-uppercase">
                <a href="/home" class="d-inline-block text-decoration-none"
                  >home</a
                >
              </li>
              <li class="text-uppercase">
                <a
                  href="/experience"
                  class="d-inline-block text-decoration-none"
                >
                  About SANDBOX
                </a>
              </li>
              <li class="text-uppercase">
                <a href="/media" class="d-inline-block text-decoration-none"
                  >media</a
                >
              </li>
              <li class="text-uppercase">
                <a href="/faq" class="d-inline-block text-decoration-none"
                  >faq</a
                >
              </li>
              <!-- <li class="text-uppercase">
                <a
                  href="/contact_us"
                  class="d-inline-block text-decoration-none"
                >
                  contact us
                </a>
              </li> -->
            </ul>
          </div>
          <div class="col third-column">
            <ul class="list-unstyled mb-0 mt-5">
              <li class="text-uppercase">
                <a
                  href="/2022"
                  class="d-inline-block text-decoration-none"
                  v-if="artists.length > 0"
                >
                  2022 Lineup
                </a>
              </li>
              <!-- <li class="text-uppercase">
                <a href="/lineup" class="d-inline-block text-decoration-none">
                  Previous lineups
                </a>
              </li> -->
              <li class="text-uppercase">
                <a href="/artists" class="d-inline-block text-decoration-none">
                  All artists
                </a>
              </li>
              <li class="text-uppercase">
                <a href="/news" class="d-inline-block text-decoration-none"
                  >News</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <picture>
      <source
        media="(max-width:768px)"
        :srcset="require('images/frontend/footer/footer-right-mob.svg')"
      />
      <img
        class="footer-shape position-absolute"
        :src="require('images/frontend/footer/footer-right.svg')"
        alt="Footer shape"
      />
    </picture>
    <div class="footer-bottom position-relative">
      <p class="m-0">
        Sandbox Festival {{ new Date().getFullYear() }}. All Rights Reserved.
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
  name: "main-footer",
  props: ["social_links", "artists"],
  data: function () {
    return {};
  },
};
</script>
