//Rails turbolinks
require("turbolinks").start();

import("./stylesheets/global.scss");
import Vue from 'vue';
import NavHeader from './header/header-component.vue';
import Footer from './footer/footer-component.vue';
import "../backend/components/libraries/vue-bootstrap"

export const EventBus = new Vue();

document.addEventListener("turbolinks:load", () => {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual';
  }

  var elementHeader = document.getElementById('div-header-nav');
  let elementFooter = document.getElementById('div-footer');

  if (elementHeader != null) {
    const headerProps = JSON.parse(elementHeader.getAttribute("props"));

    new Vue({
      render: h =>
        h(NavHeader, {
          props: headerProps,
        }),
    }).$mount(elementHeader);
  }

  if (elementFooter != null) {
    const footerProps = JSON.parse(elementFooter.getAttribute("props"));

    new Vue({
      render: (h) =>
        h(Footer, {
          props: footerProps,
        }),
    }).$mount(elementFooter);
  }
})

require.context('./stylesheets', true, /\.sass$/);
