<template>
  <header
    id="main-header"
    class="d-flex justify-content-between align-items-center position-sticky"
  >
    <!-- Nav desktop -->
    <div class="left-side">
      <b-navbar class="p-0" toggleable="lg">
        <b-navbar-toggle
          target="nav-collapse"
          class="border-0 p-0"
          v-b-toggle.sidebar-responsive
        >
          <template #default>
            <i class="fas fa-bars text-white"></i>
          </template>
        </b-navbar-toggle>

        <b-collapse is-nav>
          <b-navbar-nav class="align-items-center">
            <!-- <b-nav-item-dropdown class="text-uppercase" text="experience" left> -->
            <!-- <b-dropdown-item href="/experience"
                >About the festival</b-dropdown-item
              >
              <b-dropdown-item href="/media">media</b-dropdown-item>
              <b-dropdown-item href="/faq">faq</b-dropdown-item>
            </b-nav-item-dropdown> -->
            <b-nav-item class="text-uppercase" href="/experience"
              >About the festival</b-nav-item
            >
            <b-nav-item class="text-uppercase" href="/media">media</b-nav-item>
            <b-nav-item class="text-uppercase" href="/faq">faq</b-nav-item>

            <b-nav-item-dropdown class="text-uppercase" text="lineup" left>
              <b-dropdown-item href="/2022" v-if="artists.length > 0"
                >2022 lineup</b-dropdown-item
              >
              <!-- <b-dropdown-item href="/lineup">previous lineups</b-dropdown-item> -->
              <b-dropdown-item href="/artists">All artists</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item class="text-uppercase" href="/news">news</b-nav-item>
            <!-- <b-nav-item class="text-uppercase" href="/contact_us"
              >contact us</b-nav-item
            > -->
            <a
              class="btn btn-buy-ticket btn-buy-ticket-desktop"
              v-if="booking_visibility == '1'"
              href="http://booking.sandboxfestival.com/"
            >
              <span>Buy your pass</span>
            </a>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <div class="right-side">
      <a
        class="btn btn-buy-ticket btn-buy-ticket-mobile"
        v-if="booking_visibility == '1'"
        href="http://booking.sandboxfestival.com/"
      >
        <span>Buy your pass</span>
      </a>
      <b-link href="/home">
        <img
          :src="require('images/frontend/client-logo.png')"
          class="client-logo"
          alt="client-logo"
        />
      </b-link>
    </div>
    <!-- /Nav desktop -->

    <!-- Nav responsive -->
    <b-sidebar
      id="sidebar-responsive"
      class="menu-responsive"
      text-variant="light"
      width="100vw"
      no-header
      v-on:change="toggleBody"
    >
      <div
        class="d-flex justify-content-between align-items-center menu-header"
      >
        <div class="left-side">
          <b-navbar class="p-0" toggleable="lg">
            <b-navbar-toggle
              target="nav-collapse"
              class="border-0 p-0"
              v-b-toggle.sidebar-responsive
            >
              <template #default>
                <i class="fas fa-bars text-white"></i>
              </template>
            </b-navbar-toggle>
          </b-navbar>
        </div>
        <div class="right-side">
          <b-link href="/home">
            <img
              :src="require('images/frontend/client-logo.png')"
              class="client-logo"
              alt="client-logo"
            />
          </b-link>
        </div>
      </div>
      <img
        class="position-absolute shape-top"
        :src="require('images/frontend/header/top-right.svg')"
        alt="background shapes"
      />
      <img
        class="position-absolute shape-bottom"
        :src="require('images/frontend/header/bottom-left.svg')"
        alt="background shapes"
      />
      <div class="content">
        <ul class="menu-list list-unstyled position-relative">
          <li class="header-item text-uppercase">
            <b-link href="/home">Home</b-link>
          </li>
          <li
            class="
              header-item-has-children header-item
              text-uppercase
              pt-4
              text-decoration-none
            "
          >
            <span class="subtitle text-uppercase">EXPERIENCE</span>
            <ul class="children list-unstyled">
              <li><b-link href="/experience">About the festival</b-link></li>
              <li><b-link href="/media">Media</b-link></li>
              <li class="text-uppercase"><b-link href="/faq">faq</b-link></li>
            </ul>
          </li>
          <li
            class="
              header-item-has-children header-item
              text-uppercase
              pt-4
              text-decoration-none
            "
          >
            <span class="subtitle text-uppercase">Lineup</span>
            <ul class="children list-unstyled">
              <li>
                <b-link href="/2022" v-if="artists.length > 0"
                  >2022 lineup</b-link
                >
              </li>
              <!-- <li><b-link href="/lineup">Previous lineups</b-link></li> -->
              <li><b-link href="/artists">All artists</b-link></li>
            </ul>
          </li>
          <li class="header-item text-uppercase pt-4">
            <b-link href="/news">news</b-link>
          </li>
          <!--<li class="header-item text-uppercase pt-4">
            <b-link href="/contact_us">contact us</b-link>
          </li>-->
          <div class="social d-flex mt-4">
            <a
              v-if="social_links.facebook"
              :href="social_links.facebook"
              class="d-inline-block mr-4"
              target="_blank"
            >
              <i class="fab fa-facebook"></i>
            </a>
            <a
              v-if="social_links.instagram"
              :href="social_links.instagram"
              class="d-inline-block mr-4"
              target="_blank"
            >
              <i class="fab fa-instagram"></i>
            </a>
            <a
              v-if="social_links.soundcloud"
              :href="social_links.soundcloud"
              class="d-inline-block mr-4"
              target="_blank"
            >
              <i class="fab fa-soundcloud"></i>
            </a>
          </div>
        </ul>
      </div>
    </b-sidebar>
    <!-- /Nav responsive -->
  </header>
</template>

<script>
import "bootstrap/js/dist/dropdown.js";
import "bootstrap/js/dist/collapse.js";

$(".dropdown-toggle").dropdown();

export default {
  name: "nav-header",
  components: {},
  props: ["booking_visibility", "artists", "social_links"],
  data: function() {
    return {};
  },
  methods: {
    toggleBody(visible) {
      if (visible) {
        $("body").css("overflow-y", "hidden");
      } else {
        $("body").css("overflow-y", "auto");
      }
    },
  },
};
</script>
